





















































































































































































































































































































































































import {
    eraseCookie,
    getCookie,
} from '@/utils/cookies'
import { Component, Vue } from 'vue-property-decorator'
import { get } from 'lodash'
import { AppModule } from '@/store/modules/app'
import { UserModule } from '@/store/modules/user'

import {
    enableUser,
    me,
    updateUser,
} from '@/api/userapi/users'
import { callConsentWriter } from '@/api/consentWriter'

@Component({
    name: 'ActivationV2',
})
export default class extends Vue {
    private registrationStep = 'REGISTRY'
    private apicalling = false
    private acceptOne = false
    private acceptTwo = false
    private newsletter = false
    private registrationData: any = {
        name: '',
        surname: '',
        companyName: '',
        website: '',
        city: '',
        mobile: '',
        dbContacts: '',
        platformsUsed: '',
    }

    $refs: any

    get specialChar () {
        return /^[A-Z0-9@&'".,\-àèìòù\s]*$/i
    }

    get siteUrl () {
        return AppModule.siteUrl
    }

    get contractLink () {
        return this.siteUrl + '/termini-e-condizioni-di-servizio/'
    }

    get privacyLink () {
        return this.siteUrl + '/informativa-privacy/'
    }

    get redirectCookie () {
        return AppModule.redirectCookie
    }

    get redirectUrl () {
        return get(this.$route, 'query.redirect', getCookie(this.redirectCookie))
    }

    get user () {
        return UserModule.user
    }

    set user (val) {
        UserModule.SET_USER(val)
    }

    get userEmail () {
        return this.user.email
    }

    beforeMount () {
        this.setInitCurrentStep()
        this.setRegistrationData()
    }

    mounted () {
        this.setFocus()
        this.sendSignupGtmEvent('S2')
    }

    private sendSignupGtmEvent (eventName: any) {
        if (!this.$gtm) {
            return
        }

        this.$gtm.trackEvent({
            event: eventName,
            Signup_Category: 'Signup',
            Signup_Action: eventName,
            Signup_Value: 200,
            Signup_Label: `Form Signup ${eventName}`,
        })
    }

    private setFocus () {
        if (typeof this.$refs.autofocus !== 'undefined') {
            this.$refs.autofocus.$children[0].$refs.input.focus()
        }
    }

    private submitCurrentStep (step: string) {
        switch (this.registrationStep) {
            case 'REGISTRY':
                this.submitStep1(step)
                break
            case 'DETAILS':
                this.submitStep2(step)
                break
            case 'PRIVACY':
                this.registrationStep = step
                break
            default: break
        }
    }

    private async submitStep1 (step: string) {
        const valid = await this.$refs.registrationStepRegistry.validate()
        if (!valid) return
        try {
            await updateUser({
                firstname: this.registrationData.name,
                lastname: this.registrationData.surname,
                activationStepCompleted: this.user.activationStepCompleted && this.user.activationStepCompleted > 1 ? this.user.activationStepCompleted : 1,
            })
            const resp = await me()
            this.user = resp.data
            this.registrationStep = step
            this.sendSignupGtmEvent('S3.1')
        } catch (e) {
            console.log(e)
        }
    }

    private async submitStep2 (step: string) {
        const valid = await this.$refs.registrationStepName.validate()
        if (!valid) return
        try {
            await updateUser({
                phone: this.registrationData.mobile,
                website: this.registrationData.website.toLowerCase(),
                company: this.registrationData.companyName,
                city: this.registrationData.city,
                activationStepCompleted: this.user.activationStepCompleted && this.user.activationStepCompleted > 2 ? this.user.activationStepCompleted : 2,
            })
            const resp = await me()
            this.user = resp.data
            this.registrationStep = step
            this.sendSignupGtmEvent('S3.2')
        } catch (e) {
            console.log(e)
        }
    }

    private async submitStep3 () {
        if (this.apicalling) return
        const errors: any = {}
        if (!this.acceptOne) {
            errors.contract = ['Il campo termini e condizioni è richiesto']
        }
        if (!this.acceptTwo) {
            errors.clauses = ['Il campo clausole vessatorie è richiesto']
        }
        if (errors.contract || errors.clauses) {
            this.$refs.registrationStepConfirmation.setErrors(errors)
            return
        }
        this.apicalling = true
        try {
            // TODO: capire cosa farne di questi dati
            await updateUser({
                activationStepCompleted: 3,
                newsletter_accept: {
                    value: this.newsletter,
                    date: new Date(),
                },
                profilation_accept: {
                    value: true,
                    date: new Date(),
                },
                privacy_accept: {
                    value: true,
                    date: new Date(),
                },
            })
            await enableUser({
                dbContacts: this.registrationData.dbContacts,
                platformsUsed: this.registrationData.platformsUsed,
            })
            await this.callConsentWriter()
            const resp = await me()
            this.user = resp.data
            this.sendSignupGtmEvent('S3.3')
            if (this.redirectUrl) {
                eraseCookie(this.redirectCookie)
                window.location.href = this.redirectUrl
            } else {
                this.$router.push({
                    name: 'dashboardIndex',
                })
            }
        } catch (e) {
            console.log(e)
        }
        this.apicalling = false
    }

    private setInitCurrentStep () {
        if (!this.user.activationStepCompleted || this.user.activationStepCompleted === 0) {
            this.registrationStep = 'REGISTRY'
            return
        }
        if (this.user.activationStepCompleted === 1) {
            this.registrationStep = 'DETAILS'
            return
        }
        if (this.user.activationStepCompleted >= 2) {
            this.registrationStep = 'PRIVACY'
        }
    }

    private setRegistrationData () {
        this.registrationData = {
            name: this.user.firstname,
            surname: this.user.lastname,
            companyName: this.user.company,
            website: this.user.website,
            dbContacts: '0 - 1.000',
            platformsUsed: 'No nessuna',
            city: this.user.city,
            mobile: this.user.phone,
        }
    }

    private async callConsentWriter () {
        try {
            await callConsentWriter({
                payloadSubmit: {
                    ...this.registrationData,
                    privacyPolicy: this.acceptOne,
                    termsAndConditions: this.acceptTwo,
                    newsletter: this.newsletter,
                },
                userAgent: window.navigator.userAgent,
                referrer: window.location.href,
                formHtml: this.$refs.formHtml.innerHTML,
            })
        } catch (e) {
            console.log(e)
        }
    }
}
