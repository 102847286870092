import { serviceConsentWriter } from '@/utils/request'
import { CancelToken } from 'axios'

export const callConsentWriter = (data: any, cancelToken?: CancelToken) => {
    return serviceConsentWriter.post(
        ``,
        data,
        {
            cancelToken,
        },
    )
}
