var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"formHtml",staticClass:"vs-text-left"},[_c('transition',{attrs:{"name":"steps-transitions","mode":"out-in"},on:{"after-enter":_vm.setFocus}},[(_vm.registrationStep == 'REGISTRY')?_c('div',{key:"REGISTRY",attrs:{"id":"registrationStepRegistry"}},[_c('div',{staticClass:"vs-h600 vs-mb-6"},[_vm._v(_vm._s(_vm.$t('activation.registration.welcome')))]),_c('div',{staticClass:"vs-body-medium vs-mb-6"},[_vm._v(_vm._s(_vm.$t('activation.registration.subtitle')))]),_c('ValidationObserver',{ref:"registrationStepRegistry"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitStep1('DETAILS')}}},[_c('ValidationProvider',{attrs:{"vid":"name","name":"Nome","rules":"required|alphaSpaces"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VsInput',{ref:"autofocus",staticClass:"vs-mb-4",attrs:{"placeholder":"Nome*","type":"text","labelHidden":"","name":"name","id":"name","error":errors.length > 0,"errorMessage":errors[0]},model:{value:(_vm.registrationData.name),callback:function ($$v) {_vm.$set(_vm.registrationData, "name", $$v)},expression:"registrationData.name"}})]}}],null,false,790597013)}),_c('ValidationProvider',{attrs:{"vid":"surname","name":"Cognome","rules":"required|alphaSpaces"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VsInput',{staticClass:"vs-mb-4",attrs:{"type":"text","placeholder":"Cognome*","name":"surname","id":"surname","labelHidden":"","error":errors.length > 0,"errorMessage":errors[0]},model:{value:(_vm.registrationData.surname),callback:function ($$v) {_vm.$set(_vm.registrationData, "surname", $$v)},expression:"registrationData.surname"}})]}}],null,false,2925839054)}),_c('p',{staticClass:"vs-body-small vs-mb-4"},[_vm._v(_vm._s(_vm.$t('activation.registration.requiredFields')))]),_c('VsButton',{staticClass:"vs-mb-4",attrs:{"expand":true,"type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('activation.registration.next'))+" ")])],1)])],1):_vm._e(),(_vm.registrationStep == 'DETAILS')?_c('div',{key:"DETAILS",attrs:{"id":"registrationStepName"}},[_c('div',{staticClass:"vs-h600 vs-mb-6"},[_vm._v(" "+_vm._s(_vm.$t('activation.registration.readyToStart'))+" ")]),_c('ValidationObserver',{ref:"registrationStepName"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitStep2('PRIVACY')}}},[_c('div',{staticClass:"vs-body-medium vs-mb-6"},[_vm._v(" "+_vm._s(_vm.$t('activation.registration.compileData'))+" ")]),_c('div',{staticClass:"vs-grid md:vs-grid-cols-2 vs-gap-4 vs-mb-4"},[_c('ValidationProvider',{attrs:{"vid":"companyName","name":"Nome azienda","rules":{required:true, regex: _vm.specialChar}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VsInput',{ref:"autofocus",attrs:{"placeholder":"Nome azienda*","type":"text","name":"companyName","id":"companyName","labelHidden":"","error":errors.length > 0,"errorMessage":errors[0]},model:{value:(_vm.registrationData.companyName),callback:function ($$v) {_vm.$set(_vm.registrationData, "companyName", $$v)},expression:"registrationData.companyName"}})]}}],null,false,3603429904)}),_c('ValidationProvider',{attrs:{"vid":"website","name":"Sito web","rules":{required: true, regex: /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9A-Z]+([\-\.]{1}[a-z0-9A-Z]+)*\.[a-zA-Z]{2,5}(:[0-9]{1,5})?(\/.*)?$/ }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VsInput',{attrs:{"placeholder":"Sito web*","type":"text","name":"website","id":"website","labelHidden":"","error":errors.length > 0,"errorMessage":errors[0]},model:{value:(_vm.registrationData.website),callback:function ($$v) {_vm.$set(_vm.registrationData, "website", $$v)},expression:"registrationData.website"}})]}}],null,false,2830806059)})],1),_c('div',{staticClass:"vs-grid md:vs-grid-cols-3 vs-gap-4 vs-mb-4"},[_c('ValidationProvider',{staticClass:"md:vs-col-span-2",attrs:{"vid":"mainPhone","name":"Telefono","rules":{required: true, regex: '^[0-9\(\)\+\-\/ ]+$', libphoneNumber: true}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VsPhoneInput',{attrs:{"labelHidden":"","placeholder":"Telefono*","error":errors.length > 0,"errorMessage":errors[0]},model:{value:(_vm.registrationData.mobile),callback:function ($$v) {_vm.$set(_vm.registrationData, "mobile", $$v)},expression:"registrationData.mobile"}})]}}],null,false,1397517044)}),_c('ValidationProvider',{attrs:{"vid":"city","name":"Città","rules":"required|alphaNumSpaces"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VsInput',{attrs:{"placeholder":"Città*","type":"text","name":"city","id":"city","labelHidden":"","error":errors.length > 0,"errorMessage":errors[0]},model:{value:(_vm.registrationData.city),callback:function ($$v) {_vm.$set(_vm.registrationData, "city", $$v)},expression:"registrationData.city"}})]}}],null,false,2354586188)})],1),_c('ValidationProvider',{attrs:{"vid":"platformsUsed","name":"Piattaforma utilizzata","rules":{required: true}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VsSelect',{staticClass:"vs-mb-4",attrs:{"label":"Utilizzi già una piattaforma di Digital Marketing?","error":errors.length > 0,"errorMessage":errors[0],"options":[
                                {
                                    label: 'No nessuna',
                                    value: 'No nessuna',
                                },
                                {
                                    label: 'MailChimp',
                                    value: 'MailChimp',
                                },
                                {
                                    label: 'Brevo (ex Sendinblue)',
                                    value: 'Brevo (ex Sendinblue)',
                                },
                                {
                                    label: 'MailUp',
                                    value: 'MailUp',
                                },
                                {
                                    label: 'Altro',
                                    value: 'Altro',
                                } ]},model:{value:(_vm.registrationData.platformsUsed),callback:function ($$v) {_vm.$set(_vm.registrationData, "platformsUsed", $$v)},expression:"registrationData.platformsUsed"}})]}}],null,false,294338745)}),_c('ValidationProvider',{attrs:{"vid":"dbContacts","name":"Contatti","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('VsSelect',{staticClass:"vs-mb-4",attrs:{"label":"Quante email invii mensilmente?","error":errors.length > 0,"errorMessage":errors[0],"options":[
                                {
                                    label: '0 - 1.000',
                                    value: '0 - 1.000',
                                },
                                {
                                    label: '1.001 - 2.500',
                                    value: '1.001 - 2.500',
                                },
                                {
                                    label: '2.501 - 5.000',
                                    value: '2.501 - 5.000',
                                },
                                {
                                    label: '5.001 - 25.000',
                                    value: '5.001 - 25.000',
                                },
                                {
                                    label: '25.001 - 50.000',
                                    value: '25.001 - 50.000',
                                },
                                {
                                    label: '50.001 - 100.000',
                                    value: '50.001 - 100.000',
                                },
                                {
                                    label: 'oltre 100.000',
                                    value: 'oltre 100.000',
                                } ]},model:{value:(_vm.registrationData.dbContacts),callback:function ($$v) {_vm.$set(_vm.registrationData, "dbContacts", $$v)},expression:"registrationData.dbContacts"}})]}}],null,false,4128292586)}),_c('p',{staticClass:"vs-body-small vs-mb-4"},[_vm._v(_vm._s(_vm.$t('activation.registration.requiredFields')))]),_c('VsButton',{staticClass:"vs-mb-4",attrs:{"expand":true,"type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('activation.registration.next'))+" ")])],1)])],1):_vm._e(),(_vm.registrationStep == 'PRIVACY')?_c('div',{key:"PRIVACY",attrs:{"id":"registrationStepConfirmation"}},[_c('div',{staticClass:"vs-h600 vs-mb-6"},[_vm._v(" "+_vm._s(_vm.$t('activation.registration.hereWeAre'))+" ")]),_c('ValidationObserver',{ref:"registrationStepConfirmation"},[_c('ValidationProvider',{attrs:{"vid":"contract","name":"Contratto"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('VsCheckbox',{staticClass:"vs-mb-4",attrs:{"size":"large","name":"contract","id":"contract","labelHidden":"","error":errors.length > 0,"errorMessage":errors[0]},model:{value:(_vm.acceptOne),callback:function ($$v) {_vm.acceptOne=$$v},expression:"acceptOne"}},[_c('i18n',{attrs:{"path":"activation.registration.termsAndConditionsWarning"}},[_c('VsButton',{attrs:{"variant":"link","size":"large","url":_vm.contractLink,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t('activation.registration.contractLink'))+" ")]),_c('VsButton',{attrs:{"variant":"link","size":"large","url":_vm.privacyLink,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t('activation.registration.privacyLink'))+" ")])],1)],1)]}}],null,false,2816530144)}),_c('ValidationProvider',{attrs:{"vid":"clauses","name":"Clausole"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('VsCheckbox',{staticClass:"vs-mb-4",attrs:{"size":"large","name":"clauses","id":"clauses","labelHidden":"","error":errors.length > 0,"errorMessage":errors[0]},model:{value:(_vm.acceptTwo),callback:function ($$v) {_vm.acceptTwo=$$v},expression:"acceptTwo"}},[_c('i18n',{attrs:{"path":"activation.registration.clauses"}},[_c('VsButton',{attrs:{"url":"https://www.4dem.it/termini-e-condizioni-di-servizio/","target":"_blank","size":"large","variant":"link"}},[_vm._v(" "+_vm._s(_vm.$t('activation.registration.clausesLink'))+" ")])],1)],1)]}}],null,false,1434446095)}),_c('VsCheckbox',{staticClass:"vs-mb-4",attrs:{"name":"newsletter","size":"large","id":"newsletter","labelHidden":"","text":_vm.$t('activation.registration.subscribeMe'),"caption":_vm.$t('activation.registration.receiveContent')},model:{value:(_vm.newsletter),callback:function ($$v) {_vm.newsletter=$$v},expression:"newsletter"}}),_c('VsButton',{staticClass:"vs-mb-4",attrs:{"expand":"","size":"large","loading":_vm.apicalling,"disabled":_vm.apicalling},on:{"click":_vm.submitStep3}},[_vm._v(" "+_vm._s(_vm.$t('activation.registration.okActive'))+" ")])],1)],1):_vm._e()]),(_vm.registrationStep !== 'WELCOME')?_c('div',{staticClass:"vs-text-center vs-mb-4"},[_c('VsButton',{attrs:{"variant":"link","url":'/app/user/logout'}},[_vm._v(" "+_vm._s(_vm.$t('activation.registration.loginOtherAccount'))+" ")])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }